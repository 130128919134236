import {
  mlAuto,
  mrAuto,
  mBAuto,
  mTAuto,
} from 'assets/jss/material-kit-pro-react.jsx';
import texboxbg from 'ps-assets/img/textbox-bg.jpg';

const diabetesStyle = function (theme) {
  return {
    mlAuto,
    mrAuto,
    mBAuto,
    mTAuto,
    whiteBg: {
      backgroundColor: `white`,
      width: '100%',
      '@media (max-width: 769px)': {
        width: '764px',
      },
      '@media (max-width: 541px)': {
        width: '537px',
      },
      '@media (max-width: 416px)': {
        width: '413px',
      },
      '@media (max-width: 412px)': {
        width: '408px',
      },
      '@media (max-width: 376px)': {
        width: '372px',
      },
      '@media (max-width: 361px)': {
        width: '357px',
      },
      '@media (max-width: 321px)': {
        width: '318px',
      },
      '@media (max-width: 281px)': {
        width: '277px',
      },
      '@media (max-width: 278px)': {
        width: '100%',
      },
    },
    customContainer: {
     color:  '#666666',
      lineHeight: "1.2",
      fontSize : '12px',
      width: `100%`,
      [theme.breakpoints.up(`sm`)]: {
        width: `75%`,
        ...mlAuto,
        ...mrAuto,
      },
    },
    headerH1:{
      color: '#666666' ,
      paddingLeft: '3%',
      textAlign: 'center'
    },
    header: {
      display: `inline-flex`,
      '& img': {
        width: `225px`,
        height: `101px`,
      },
      '& p': {
        // marginLeft: `10%`,
        // fontSize: `16px`,
        // height: `100%`,

        // color: `#666666`,
        // padding: `15px`,
        // lineHeight: `20px`,
        color: 'black',
        height: '33%',
        padding: '15px',
        fontSize: '12px',
        ...mBAuto,
        ...mTAuto,
        marginLeft: '10%',
        lineHeight: '14px',
        borderLeft: '1px solid',
        marginTop: '1rem',
        fontFamily: `Georgia, "Times New Roman", Times, serif`,
        marginBottom: '1rem',
        '@media (max-width: 500px)': {
          fontSize: '20px',
          lineHeight: '18px',
          color: '#666666',
          marginTop: '0rem',
          borderLeft: '0px',
          paddingBottom:'0px',
          marginBottom:'0px'
        },
        '@media (max-width: 420px)': {
          fontSize: '13px',
          lineHeight: '26px',
          color: '#666666',
          marginLeft: '0px !important',
          float: 'left !important',
          borderLeft: '0px',
          paddingBottom:'0px',
          marginBottom:'0px'
        },
      },
      '@media (max-width: 420px)': {
        display: 'block !important',
      },
    },
    diabetesImg: {
      textAlign: 'left',
      '@media (max-width: 1025px)': {
        textAlign: 'left',
        marginRight:'0px !important',
      },
      '@media (max-width: 800px)': {
        textAlign: 'center',
        marginRight:'-15px !important'
      },
      '@media (max-width: 500px)': {
        textAlign: 'left',
      },
    },
    widthcontainer: {
      width: '85%',
      paddingLeft: '15%',
      '@media (max-width: 800px)': {
        width: '100%',
        paddingLeft: '0px',
      },
      '@media (max-width: 500px)': {
        width: '100%',
        paddingLeft: '0px',
      },
    },

    diabetesImgTwo: {
      '@media (max-width: 500px)': {
        width: '100%',
      },
    },

    marginLeftAlign: {
      '@media (max-width: 770px)': {
        marginLeft: '7%',
        marginRight:'7%'
      },
    },
    middle: {
      display: `inline-flex`,
      width: `inherit`,
      '@media (max-width: 420px)': {
        display: 'block !important',
        width: '',
      },
    },
    middleContent: {
      height: `100%`,
      ...mTAuto,
      ...mBAuto,
    },
    featureliststyle: {
      marginLeft: '0.8rem',
      fontSize: '0.9rem',
    },
    keyFeature: {
      '& p': {
        borderBottom: `1px solid #e7ecee`,
        fontWeight: `bold`,
      },
      '& ul': {
        listStyle: `none`,
      },
      borderBottom: `1px solid #e7ecee`,
    },

    arrowstyle: {
      marginBottom: '0.4rem',
      paddingRight: '0.4rem',
    },
    form: {
      background: '#f8f8f8',
      border : '1px solid #cccccc',
      margin: `36px 0 41px 0`,
      borderRadius: `5px`,
      padding: `20px`,
      fontSize: `14px`,
      color: `#666666`,
    },
    notice: {
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '14px',
      color: '#333333',
      marginRight: `1%`,
      // marginLeft: `1%`,
    },
    fieldContainer: {
      display: `flex`,
      marginTop: `1%`,
      marginBottom: `1%`,
      '@media (max-width: 420px)': {
        // marginLeft: '65px !important',
        display: 'block !important',
      },
    },
    privacyPolicy: {
      marginTop:'1.5%',
      '& p' : {
      fontSize : '12px',
      marginBottom : '0px'
      },
      marginRight: `1%`,
      marginLeft: `1%`,
      '& label': {
        '& span': {
          color: `red`,
        },
      },
    },
    textBox: {
      width: `48%`,
      marginRight: `1%`,
      marginLeft: `1%`,
      '@media (max-width: 420px)': {
        width: '100%',
      },
      '& input': {
        
        backgroundColor: `transparent`,
        padding: `0 5px`,
        fontSize: `12px`,
        color: `#666666`,
        height: `27px`,
        width: `100%`,
        '&:focus': {
          outline: `-webkit-focus-ring-color auto 1px`,
        },
      },
      '& select': {
        backgroundColor: `transparent`,
        background: `url(${texboxbg}) no-repeat`,
        padding: `0 5px`,
        fontSize: `12px`,
        color: `#666666`,
        height: `26px`,
        width: `100%`,
      },
      '& label': {
        '& span': {
          color: `red`,
        },
      },
    },
    resetPosition:{
    marginLeft:'3%',
    '@media (max-width: 770px)': {
      marginLeft: '3%',
    }, 
    '@media (max-width: 760px)': {
      marginLeft: '7%',
    },    
    '@media (max-width: 420px)': {
      marginLeft: '14%',
    },
    },
    buttonsDiv: {
      marginTop:'2%',
      '& img': {
        marginRight: `1%`,
        curson: `pointer`,
      },
      '@media (max-width: 770px)': {
        marginTop:'4%',
      },
      '@media (max-width: 420px)': {
        textAlign: 'center !important',
      },
    },
    paddingStyle: {
      '@media (max-width: 420px)': {
        paddingLeft: '13px !important',
      },
    },
    footer: {
      textAlign: `center`,
      backgroundColor: `#ececec`,
      fontSize: `12px`,
      paddingLeft: '1%',
      paddingRight: '1%',
    },

    lineStyle: {
      '@media (max-width: 420px)': {
        display: 'none',
      },
    },
    featuresListStyle: {
      '& ul': {
        marginBottom : '10px',
        '& li': {
          marginBottom: '5px',
          fontSize: '15px',
          lineHeight: '17px',
          '@media (max-width: 770px)': {
            paddingRight: '1.7%',
          },
          '@media (max-width: 700px)': {
            paddingRight: '0.5%',
            marginBottom: '9px',
          fontSize: '15px',
          lineHeight: '22px',
          },
        }
      },
      '& p': {
        fontSize: '16px',
          lineHeight: '20px',
          marginTop:'14px',
          '@media (max-width: 770px)': {
            paddingRight: '1.9%',
          },
          '@media (max-width: 700px)': {
            paddingRight: '0.5%',
          },
      },
      '@media (max-width: 1025px)': {
        paddingLeft: '11.5%'
        },
        '@media (max-width: 1000px)': {
          paddingLeft: '5.5%',
          },
    },
    GridPosition:{
      '@media (max-width: 770px)': {
        paddingLeft: '6% !important',
        paddingTop: '3% !important'
      },
      '@media (max-width: 700px)': {
        paddingRight: '7.5% !important',
        paddingTop: '0.5% !important'
      },
      },
    weblink:{
      textDecoration : 'underline'
    }
  };
};

export default diabetesStyle;
