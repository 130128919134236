import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import root from 'window-or-global';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'ps-assets/img/diabetes-logo.jpg';
import heartDiabetes from 'ps-assets/img/heart-img.png';
import starTitle from 'ps-assets/img/star-tit.png';
import submitButtonImg from 'ps-assets/img/submit-btn.jpg';
import resetButtonImg from 'ps-assets/img/reset-btn.jpg';
import arrow from 'ps-assets/img/bullet.gif';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { landingPageLMSCall } from 'util/lmsRequest';
import diabetesStyle from 'ps-assets/jss/diabetesStyle.jsx';
import Hidden from '@material-ui/core/Hidden';
import {  browserWindow,browserDocument } from "util/localStorageHelper";
// import currentOpeningStyle from "ps-assets/jss/currentOpeningStyle.jsx";

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

const inititalState = {
  name: ``,
  email: ``,
  mobile: ``,
  pincode: ``,
  privacyPolicy: true,
  submitButton: false,
};

class Diabetes extends React.Component {
  state = inititalState;

  componentDidMount = () => {
    browserDocument.body.style.backgroundColor = `white`;
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `complete-health-insurance`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${
          response.zohoEntityId ? response.zohoEntityId : ``
        }${queryParams ? `&${queryParams}` : ``}`,
        `_self`
      );
    } catch (exception) {
      console.error(`except - `, exception);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            } else alert('Please Enter PinCode');
          } else alert('Read Terms and Conditions');
        } else alert('Enter Mobile Number');
      } else alert('Enter Your Email');
    } else alert('Enter Your Name');
  };

  handleReset = () => {
    this.setState({ ...inititalState });
  };

  render() {
    const { classes } = this.props;
    let list = [
      'India`s first Health insurance policy for people living with Type1 and Type2 Diabetes',
      'No Pre acceptance medical check-up required',
      'All complications arising out of Type1 & Type2 Diabetes covered after 30 days*.',
      'Provides cover towards regular Hospitalization also',
      'Policy can be also taken on Floater basis – self + spouse',
      '400+ Day care procedures covered',
    ];
    return (
      <>
        <div className={classes.whiteBg}>
          <div className={classNames(classes.customContainer)}>
            <div className={classes.header}>
              <img src={logo} alt='cardiac-care-logo' />
              <Hidden smUp>
                <h1 className={classes.headerH1}>Diabetes Health Insurance Policy</h1>
              </Hidden>
              <p style={{ color: '#666666' }}>
                India`s largest health insurance company, Star Health and Allied
                Insurance brings you Star Diabetes safe policy, a trailblazer
                among health insurance policies in India and diabetes care
                policies in general. This policy helps those affected by
                Diabetes and offers insurance protection for treating commonly
                occurring complications caused by diabetes.
                <br />
                <br />
                Insure and protect yourself with Star Cardiac care policy today.
              </p>
            </div>
          </div>
          <hr className={classes.lineStyle} />
          <div className={classes.widthcontainer}>
          <GridContainer style={{padding:'0px'}}>
          <Hidden mdUp>
            <div className={classes.marginLeftAlign}>
              <form>
                <div className={classes.form}>
                  <div className={classes.notice}>
                    Fill in the details to know more about our Star Diabetes
                    safe Insurance Policy.
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Name<span>*</span>
                      </label>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Email<span>*</span>
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Mobile No<span>*</span>
                      </label>
                      <input
                        type='text'
                        id='mobile'
                        name='mobile'
                        value={this.state.mobile}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        PinCode<span>*</span>
                      </label>
                      <input
                        type='text'
                        id='pincode'
                        name='pincode'
                        value={this.state.pincode}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <label style={{color:'#666666'}}>
                      <span style={{color:'red'}}>*</span> Mandatory Fields
                    </label>
                  <div className={classes.privacyPolicy}>
                    <p>
                      <input
                        type='checkbox'
                        onClick={this.handlePrivacyPolicy}
                        checked={this.state.privacyPolicy}
                      />
                      I have read and agree to the
                      <a
                        href='/static-privacy-policy'
                        target='_blank'
                        style={{
                          cursor: `pointer`,
                          textDecoration: 'underline',
                          margin: `0px 3px`,
                        }}
                        rel={`noopener noreferrer`}
                      >
                        Privacy Policy
                      </a>
                      I authorize Star Health Insurance to contact me via email
                      or phone or SMS.
                    </p>
                    
                  </div>
                  <div className={classes.buttonsDiv}>
                    <img
                      src={submitButtonImg}
                      alt='submit-button'
                      onClick={this.handleSubmit}
                    />
                    <img
                      src={resetButtonImg}
                      className={classes.resetPosition}
                      alt='submit-button'
                      onClick={this.handleReset}
                    />
                  </div>
                </div>
              </form>
            </div>
            </Hidden>
              <GridItem sm={5} md={5} xs={12} className={classes.diabetesImg} style={{padding:'0px'}}>
                <img
                  src={heartDiabetes}
                  alt='toi-cardiac-care-heart-img'
                  className={classes.diabetesImgTwo}
                />
              </GridItem>

              <GridItem
                GridItem
                sm={7}
                md={7}
                xs={12}
                style={{padding:'0px'}}
                className={classes.GridPosition}
              >
                <div
                   className={classes.featuresListStyle}
                >
                  <img
                    src={starTitle}
                    alt='star-title-here'
                    style={{ width: '100%', marginTop: '5.5%' ,marginBottom:'10px'}}
                    // className={classes.diabetesImgTwo}
                  />
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: '0px',
                    }}
                  >
                    {list.map((item, index) => (
                      <li
                        key={index}
                        style={{ listStyle: 'none'}}
                        // className={classes.featureliststyle}
                      >
                        <img
                          src={arrow}
                          style={{
                            width: '1%',
                            marginTop: '-2%',
                            marginRight: '1%',
                          }}
                          // className={classes.arrowstyle}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                  <p>
                    <strong>*</strong> 15 months waiting period applicable
                    towards diabetes complications associated with Renal, Heart,
                    Eyes & Foot ulcers
                  </p>
                </div>
              </GridItem>
            </GridContainer>
            <Hidden smDown>
            <div className={classes.marginLeftAlign}>
              <form>
                <div className={classes.form}>
                  <div className={classes.notice}>
                    Fill in the details to know more about our Star Diabetes
                    safe Insurance Policy.
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Name<span>*</span>
                      </label>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Email<span>*</span>
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className={classes.fieldContainer}>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        Mobile No<span>*</span>
                      </label>
                      <input
                        type='text'
                        id='mobile'
                        name='mobile'
                        value={this.state.mobile}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={classes.textBox}>
                      <label  style={{color:'#666666'}}>
                        PinCode<span>*</span>
                      </label>
                      <input
                        type='text'
                        id='pincode'
                        name='pincode'
                        value={this.state.pincode}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <label style={{color:'#666666'}}>
                      <span style={{color:'red'}}>*</span> Mandatory Fields
                    </label>
                  <div className={classes.privacyPolicy}>
                    <p>
                      <input
                        type='checkbox'
                        onClick={this.handlePrivacyPolicy}
                        checked={this.state.privacyPolicy}
                      />
                      I have read and agree to the
                      <a
                        href='/static-privacy-policy'
                        target='_blank'
                        style={{
                          cursor: `pointer`,
                          textDecoration: 'underline',
                          margin: `0px 3px`,
                        }}
                        rel={`noopener noreferrer`}
                      >
                        Privacy Policy
                      </a>
                      I authorize Star Health Insurance to contact me via email
                      or phone or SMS.
                    </p>
                    
                  </div>
                  <div className={classes.buttonsDiv}>
                    <img
                      src={submitButtonImg}
                      alt='submit-button'
                      onClick={this.handleSubmit}
                    />
                    <img
                      src={resetButtonImg}
                      className={classes.resetPosition}
                      alt='submit-button'
                      onClick={this.handleReset}
                    />
                  </div>
                </div>
              </form>
            </div>
            </Hidden>
          </div>
          
          <div className={classes.footer}>
            <div
              className={classes.customContainer}
              style={{ paddingTop: '24px', paddingBottom: '32px' }}
            >
              IRDAI REGISTRATION NUMBER. 129
              <div>
                Insurance is subject matter of solicitation | For more details
                on risk factors, terms and conditions, Please read the sales
                brochure carefully before concluding a
              </div>
              <div>sale | SHAI/13-14/WEB/001</div>

              <br></br>
              <div >
                <strong>
                  Star Health and Allied Insurance Company Limited.
                </strong>
              </div>
              <div>
                Corporate Office: No.1, New Tank Street, Valluvar Kottam High
                Road, Nungambakkam, Chennai - 600034. Sales & Services - 044 4674 5800
              </div>
              <br/>
              <div >
                For further details visit{' '}
                <a
                  href='http://www.starhealth.in/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='weblink'
                  style={{textDecoration:'underline'}}
                >
                  www.starhealth.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Diabetes.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(diabetesStyle)(Diabetes);
